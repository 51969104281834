import icon from '@/assets/icon.png';
import wechatjt from '@/assets/wechatjt.png';
import apple from '@/assets/apple.png';
import android from '@/assets/android.png';
import safe from '@/assets/safe.png';
import mores from '@/assets/mores.png';
import down_back from '@/assets/down_back.png';
import down_c from '@/assets/down_c.png';
import safari from '@/assets/safari.png';
import ponits from '@/assets/ponits.png';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
export default {
  name: 'DownPage',

  setup() {
    const isInitSuccess = ref(false);
    const route = useRoute();
    const isWechat = ref(false);
    const show = ref(false);
    const isIos = ref(false);
    const userAgent = navigator.userAgent.toLowerCase();
    const match = userAgent.match(/MicroMessenger/i);

    if (match !== null && match.toString() === 'micromessenger') {
      isWechat.value = true;
    }

    isIos.value = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

    const close = () => {
      show.value = false;
    };

    const downIos = () => {
      if (isWechat.value) {
        show.value = true;
      } else {
        window.open("https://apps.apple.com/us/app/%E7%B3%96%E6%9E%9C%E6%8B%A9%E5%A5%B9/id6741799057");
      }
    }; //下载按钮点击时调用方法


    const down = () => {
      // if(isInitSuccess.value) {
      //   if (isWechat.value) {
      //     show.value = true
      //   } else {
      //     ShareTrace.download()
      //   }
      // } else {
      //如果初始化失败建议自己指定下载地址，虽然不能获取安装参数但是不会影响用户的下载
      if (isWechat.value) {
        show.value = true;
      } else {
        if (route.params.code !== undefined && route.params.code !== '') {
          window.location.href = 'https://api.yanruyu.love/web-api/index/download/' + route.params.code;
        } else {
          window.location.href = 'https://www.yanruyu.love/download/androidRelease.apk';
        }
      } // }

    };

    return {
      icon,
      down,
      wechatjt,
      isWechat,
      show,
      apple,
      android,
      safe,
      mores,
      down_back,
      safari,
      isIos,
      close,
      down_c,
      ponits,
      downIos
    };
  }

};